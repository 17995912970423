import { Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

export interface Entity {
  id?: string; // Optional for new entities
}

export class CrudFirebase<T extends Entity> {

  private collection: AngularFirestoreCollection<T>;
  private storage: AngularFireStorage;

  constructor(
    private afs: AngularFirestore,
    private collectionName: string,
    private str?: AngularFireStorage) {
    this.collection = afs.collection<T>(collectionName);
    console.log("2222",str)
    this.storage = str;
  }

  getAll() : Observable<T[]> {
    return this.collection
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }
  getWhere(fieldPath: string | firebase.firestore.FieldPath, opStr: firebase.firestore.WhereFilterOp, value: any) {
    return this.collection.ref.where(fieldPath, opStr, value);
  }
  getRef(): CollectionReference {
    return this.collection.ref;
  }
  public update(id:string, t: T): Promise<void> {
    return this.collection.doc(id).update(t);
  }
  public add(t: T) {
    return this.collection.add(t);
  }
  public deleteById(t: T): Promise<void> {
    return this.collection.doc(t.id).delete();
  }
  public putImage( Image: File ): AngularFireUploadTask{
    const filePath = `images/${Image.name}`;
    const fileRef = this.storage.ref( filePath );
    return fileRef.put(Image);
  }
  public getURLImage(name: string) {
    const filePath = `images/${name}`;
    const fileRef = this.storage.ref(filePath);
    return fileRef.getDownloadURL()
  }
}
