import { Component, OnInit, Input } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularFirestore } from '@angular/fire/firestore';
import { CrudFirebase, Entity } from "app/shared/firebase/crud-firebase";

@Component({
  selector: "app-modal-content",
  template: `
    <div class="modal-header">
      <h5 class="modal-title text-center" style="font-weight:bold;">{{ producto.nombre }}</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-xs-12  col-sm-12 col-md-12 offset-md-12">
        <img
          src="{{producto.img}}"
          class="img-thumbnail img-responsive"
          alt="Rounded Image"
        />
        <p class="text-center">{{producto.detalle}}</p>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-success"
        (click)="solicitar()"
        style="width: 100%;margin: 3px;"
      >
        Solicitar
      </button>
    </div>
  `,
})
export class NgbdModalContent {
  @Input() producto: ProductoModel;
  @Input() admin: AdminModel;

  constructor(public activeModal: NgbActiveModal) {}
  solicitar() {
    Utils.sendMessageWhatsapp(this.admin.whatsapp, `Solicito el producto ${this.producto.nombre}`);
    this.activeModal.close('Close click');
  }
}

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  crudFirebaseAdmin: CrudFirebase<AdminModel>;
  crudFirebaseProducto: CrudFirebase<ProductoModel>;
  focus: any;
  focus1: any;
  comunicar = {
    nombre: "",
    mensaje: "",
  };
  admin: AdminModel = null;
  productos: ProductoModel[] = null;


  constructor(private modalService: NgbModal, private afs: AngularFirestore) {
    this.crudFirebaseAdmin = new CrudFirebase<AdminModel>(afs, 'aguasvictorb_admin');
    this.crudFirebaseProducto = new CrudFirebase<ProductoModel>(afs, 'aguasvictorb_producto');
  }
  open(producto: ProductoModel) {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.producto = producto;
    modalRef.componentInstance.admin = this.admin;
  }

  ngOnInit() {
    const admin = this.crudFirebaseAdmin.getAll();
    const producto = this.crudFirebaseProducto.getAll();
    admin.subscribe( ad => this.admin = ad[0] );
    producto.subscribe( pro => this.productos = pro )
  }

  comunicarse() {
    Utils.sendMessageWhatsapp(this.admin.whatsapp, `Hola Soy ${this.comunicar.nombre}, ${this.comunicar.mensaje}`);
  }
}
interface ProductoModel extends Entity {
  nombre: string;
  detalle: string;
  img: string;
}
interface AdminModel extends Entity {
  nombre: string;
  avatar: string;
  cargo: string;
  descripcion: string;
  imgPrincipal: string;
  titulo: string;
  presentacion: string;
  whatsapp: string;
}
export class Utils {
  static sendMessageWhatsapp(number: string, menssage: string) {
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=${menssage}`, "_blank");
  }
}